import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiAccountPlus, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import UserForm from '../UserForm/UserForm';
import { createUser, startCreateUser } from '../../../redux/modules/users';
import Role from '../../../components/Role/Role';
import _ from 'lodash';

const UserCreate = () => {
    const dispatch            = useDispatch();
    const { path }            = useRouteMatch();
    const { t }               = useTranslation();
    const user                = useSelector(state => state.users.user);
    const successfullyCreated = useSelector(state => state.users.userSuccessfullyCreated);

    useEffect(() => {
        dispatch(startCreateUser());
    }, [dispatch]);

    if (successfullyCreated && user) {
        return <Redirect to={ `edit/${user.id}` } />
    }

    const actions = <Link to={ `${path.replace('new', '')}` }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleUserCreation = (values) => {
        // cast notify_payments value
        if (_.isString(values.notify_payments))
            values.notify_payments = values.notify_payments === 'true';

        dispatch(createUser(values));
    };

    return (
        <div className="UserCreate">
            <Role not has='admin'>
                <Redirect to={'/'}/>
            </Role>
            <Row>
                <Col>
                    <PageTitle
                        title={ t('users.add_title') }
                        icon={ <Icon path={mdiAccountPlus} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <UserForm onSubmit={ handleUserCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default UserCreate;
