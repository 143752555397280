import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Label, FormGroup, Card, CardBody, CardHeader, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { mdiContentSave, mdiLoading, mdiAccountCancel, mdiAccountCheck, mdiAlert, mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { User } from '../../../models';
import UserAccoutsField from '../../../components/UserAccoutsField/UserAccoutsField';

let UserForm = ({ isCreation, handleSubmit, pristine, submitting, handleEnable, handleDisable }) => {
    const { t } = useTranslation();

    const [ success, setSuccess ] = useState(false);

    const currentUser  = useSelector(state => state.auth.user);
    const { accounts } = useSelector((state) => state.accounts);

    const {
       user, isLoadingOne, loadingOneErrorMessage, userSuccessfullyCreated
    } = useSelector(state => state.users)

    let userAccountIds = [];
    if (user && user.account_ids)
        userAccountIds = user.account_ids;

    const isActivable = user && !user.is_active;
    const isDeactivable = user && currentUser && user.id !== currentUser.id && user.is_active;

    useEffect(() => {
        if (userSuccessfullyCreated)
            setSuccess(true);
    }, [ userSuccessfullyCreated ]);

    return (
        <Form autoComplete="off" className="form" onSubmit={ handleSubmit }>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle>{ isCreation ? t('users.add_title') : t('users.edit_title') }</CardTitle>
                        </Col>
                        <Col className="text-right">
                            { !isCreation && isActivable &&
                                <Button color="info" onClick={ () => { handleEnable(user) } }>
                                    <Icon path={ mdiAccountCheck } size={ 1 } color="white" /> { t('users.enable') }
                                </Button> }
                            { !isCreation && isDeactivable &&
                                <Button color="danger" onClick={ () => { handleDisable(user) } }>
                                    <Icon path={ mdiAccountCancel } size={ 1 } color="white" /> { t('users.disable') }
                                </Button> }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label for="gender">{ t('users.gender') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('users.gender') } name="gender" id="gender" required>
                            { User.genders().map(gender => <option key={ gender } value={ gender }>{ t(`users.genders.${gender}`) }</option>) }
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="fullname">{ t('users.fullname') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('users.fullname') } name="fullname" id="fullname" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="email">{ t('users.email') }</Label>
                        <Field component="input" className="form-control" placeholder={ t('users.email') } name="email" id="email" required />
                    </FormGroup>

                    <FormGroup>
                        <Label for="new-password">{ t('users.password') }</Label>
                        <Field component="input" autoComplete="new-password" type="password" className="form-control" placeholder={ t('users.password') } name="new-password" id="new-password" required={ isCreation } />
                    </FormGroup>

                    <FormGroup>
                        <Label for="new-password-confirmation">{ t('users.password_confirmation') }</Label>
                        <Field component="input" autoComplete="new-password" type="password" className="form-control" placeholder={ t('users.password_confirmation') } name="new-password-confirmation" id="new-password-confirmation" required={ isCreation } />
                    </FormGroup>

                    <FormGroup>
                        <Label for="role">{ t('users.role') }</Label>
                        <Field component="select" className="form-control" placeholder={ t('users.role') } name="role" id="role" required>
                            { isCreation && <option>---</option> }
                            {currentUser.isSuperAdmin() &&
                            <option key={ User.ROLE_SUPER_ADMIN } value={ User.ROLE_SUPER_ADMIN }>
                                { t(`users.roles.${User.ROLE_SUPER_ADMIN}`) }
                            </option>
                            }
                            {currentUser.isAdmin() &&
                            <option key={ User.ROLE_ADMIN } value={ User.ROLE_ADMIN }>
                                { t(`users.roles.${User.ROLE_ADMIN}`) }
                            </option>
                            }
                            <option key={ User.ROLE_USER } value={ User.ROLE_USER }>
                                { t(`users.roles.${User.ROLE_USER}`) }
                            </option>
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="notify_payments">{ t('users.notify_payments') }</Label>
                        <Field component="select" className="form-control" name="notify_payments" id="notify_payments" required>
                            <option value={ false }>{ t('common.no') }</option>
                            <option value={ true }>{ t('common.yes') }</option>
                        </Field>
                    </FormGroup>

                    <FormGroup>
                        <Label for="role">{ t('users.account_current') }</Label>
                        <Field component="select" className="form-control" name="account_id" id="account_id" required>
                            { isCreation && <option>---</option> }
                            { (accounts || []).map(account => <option key={ account.id } value={ account.id }>{ account.name }</option>) }
                        </Field>
                    </FormGroup>
                    { !isCreation && currentUser.isSuperAdmin() &&
                    <FormGroup>
                        <Label for="accounts-multiple">{t('users.accounts')}</Label>
                        <UserAccoutsField name="user_accounts" accounts={ accounts } values={ userAccountIds }/>
                    </FormGroup>
                    }
                    {(loadingOneErrorMessage?.length > 0) &&
                        <div className="w-100 text-center">
                            <Label className="text-danger">
                                <Icon path={ mdiAlert } size={ 1 } color="red"/>&nbsp;
                                {loadingOneErrorMessage}
                            </Label>
                        </div>
                    }
                    {success &&
                        <div className="w-100 text-center">
                            <Label className="text-success">
                                <Icon path={ mdiCheck } size={ 1 } color="green"/>&nbsp;
                                { t('users.successfully_created') }
                            </Label>
                        </div>
                    }
                </CardBody>
                <CardFooter className="text-right">
                    <Button type="submit" color="success" disabled={ pristine || submitting || isLoadingOne }>
                        { isLoadingOne && <Icon path={ mdiLoading } size={ 1 } spin={ 1 } color="white" /> }
                        { !isLoadingOne && <Icon path={ mdiContentSave } size={ 1 } color="white" /> }
                        &nbsp;{t("common.save")}
                    </Button>
                </CardFooter>
            </Card>
        </Form>
    );
}

UserForm = reduxForm({
    form: 'userForm',
    enableReinitialize: true,
})(UserForm)

UserForm = connect(
    state => ({
        initialValues: state.users.user
    })
)(UserForm)

export default UserForm;
