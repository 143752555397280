import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mdiAccountEdit, mdiArrowLeftCircle } from '@mdi/js';
import { Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import UserForm from '../UserForm/UserForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadUser, updateUser, enableUser, disableUser } from '../../../redux/modules/users';
import _ from 'lodash';

const UserEdit = () => {
    const dispatch   = useDispatch();
    const { t }      = useTranslation();
    const { userId } = useParams();

    useEffect(() => {
        dispatch(loadUser(userId))
    }, [dispatch, userId])

     const actions = <Link to="/users">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleUserEdition = (values) => {
        // cast notify_payments value
        if (_.isString(values.notify_payments))
            values.notify_payments = values.notify_payments === 'true';

        dispatch(updateUser(values));
    };

    const handleUserDisabling = (user) => {
        dispatch(disableUser(user));
    }

    const handleUserEnabling = (user) => {
        dispatch(enableUser(user));
    }

    return (
         <div className="UserEdit">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('users.edit_title') }
                        icon={ <Icon path={ mdiAccountEdit } color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    <UserForm
                        onSubmit={ handleUserEdition }
                        isCreation={ false }
                        handleEnable={ handleUserEnabling }
                        handleDisable={ handleUserDisabling }
                    />
                </Col>
            </Row>
        </div>
    );
}

export default UserEdit;
