import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Row, Col, ButtonGroup, Button, Modal,
    ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { Batchs } from '../../../services';
import { loadBatchs, runBatch } from '../../../redux/modules/batchs';
import StyledTable from '../../../components/StyledTable/StyledTable';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Icon from '@mdi/react';
import {
    mdiSemanticWeb,
    mdiBank,
    mdiCalendar,
    mdiFile,
    mdiContentSaveOutline,
    mdiCheck,
    mdiContentDuplicate,
    mdiAlertCircle,
    mdiAlertCircleOutline,
    mdiRunFast,
    mdiLoading,
    mdiEye,
    mdiClose,
    mdiFileDownload
} from '@mdi/js';
import moment from 'moment';
import _ from 'lodash';

import './BatchsList.css';

const BatchsList = () => {
    const dispatch = useDispatch();
    const { t }    = useTranslation();
    const {
        isLoading,
        batchs,
        isRunning,
        hasRunningError,
        runningErrorMessage
    } = useSelector(state => state.batchs);

    const [batchToShow, setBatchToShow] = useState(null);
    const [mustShowModal, showModal]    = useState(false);

    const toggleModal = () => {
        showModal(!showModal);
    };

    const showBatch  = (batch) => {
        setBatchToShow(batch);
        showModal(true);
    }
    const closeBatch = () => {
        showModal(false);
        setBatchToShow(null);
    }

    useEffect(() => {
        dispatch(loadBatchs());
    }, [dispatch]);

    const run = (id) => {
        dispatch(runBatch(id));
    };

    if (!batchs || isLoading) {
        return <FullPageLoader />
    }

    const cellFormatter = (text, smallText, capitalizeSmallText, hasWarning) =>
        <>
            <div>
                { text }
            </div>
            {smallText &&
                <small className={capitalizeSmallText ? 'capitalize': ''}>{ smallText }</small>
            }
        </>

    const headerFormatter = (column, colIndex, columnName) =>
        <>
            <Icon path={ getHeaderIconPath(columnName) } size={ 1 } color="white" />
            {' '}
            { column.text }
        </>;

    const actionFormatter = (id, batch) =>
        <ButtonGroup>
            <a target="_blank" rel="noopener noreferrer" href={ Batchs.getDownloadFileLink(batch) } className="btn btn-info btn-sm">
                <Icon path={ mdiFileDownload } size={ 1 } color="white" />
            </a>
            { (batch.nb_invalid > 0 || !batch.success) && (
                <Button color="warning" size="sm" onClick={() => { run(id); }}>
                    {!isRunning &&
                        <Icon path={ mdiRunFast } size={ 1 } color="wblack" />
                    }
                    {isRunning &&
                        <Icon path={ mdiLoading } spin={1} size={ 1 } color="wblack" />
                    }
                </Button>
            )}
        </ButtonGroup>;

    const getHeaderIconPath = (columnName) => {
        switch (columnName) {
            case 'created_at':
                return mdiCalendar;
            case 'filename':
                return mdiFile;
            case 'manager':
                return mdiBank;
            case 'nb_saved':
                return mdiContentSaveOutline;
            case 'nb_valid':
                return mdiCheck;
            case 'nb_duplicates':
                return mdiContentDuplicate;
            case 'nb_invalid':
                return mdiAlertCircleOutline;
            case 'success':
                return mdiCheck;
            case 'errors':
            default:
                return mdiAlertCircle;
        }
    }

    const shortenFilename = (filename) => {
        if (filename.length > 25)
            return `${filename.substring(0, 10)}...${filename.substring(filename.length - 12)}`;

        return filename;
    };

    const columns = [{
        dataField: 'created_at',
        text: t('batchs.created_at'),
        sort: true,
        formatter: (created_at, row) => cellFormatter(
            moment(created_at).format('lll')
        ),
        headerFormatter: (col, index) => headerFormatter(col, index, 'created_at'),
        csvFormatter: (created_at, row, rowIndex, formatExtraData) => {
            return moment(created_at).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'filename',
        text: t('batchs.filename'),
        sort: true,
        hidden: true,
        headerFormatter: (col, index) => headerFormatter(col, index, 'filename'),
    }, {
        dataField: 'manager',
        text: t('batchs.manager'),
        sort: true,
        formatter: (manager, batch) => cellFormatter(
            manager, shortenFilename(batch.filename)
        ),
        headerFormatter: (col, index) => headerFormatter(col, index, 'manager'),
    }, {
        dataField: 'nb_saved',
        text: t('batchs.nb_saved'),
        sort: true,
        headerFormatter: (col, index) => headerFormatter(col, index, 'nb_saved'),
    }, {
        dataField: 'nb_valid',
        text: t('batchs.nb_valid'),
        sort: true,
        headerFormatter: (col, index) => headerFormatter(col, index, 'nb_valid'),
    }, {
        dataField: 'nb_duplicates',
        text: t('batchs.nb_duplicates'),
        sort: true,
        headerFormatter: (col, index) => headerFormatter(col, index, 'nb_duplicates'),
    }, {
        dataField: 'nb_invalid',
        text: t('batchs.nb_invalid'),
        sort: true,
        headerFormatter: (col, index) => headerFormatter(col, index, 'nb_invalid'),
    }, {
        dataField: 'success',
        text: t('batchs.success'),
        sort: true,
        sortValue: (success, row) => success ? 1 : 0,
        formatter: (success, batch) => {
            return (
                <Icon path={ success ? mdiCheck : mdiAlertCircleOutline } size={ 1 } color="white" />
            );
        },
        headerFormatter: (col, index) => headerFormatter(col, index, 'success'),
    }, {
        dataField: 'errors',
        text: t('batchs.errors'),
        sort: true,
        sortValue: (errors, row) => Object.keys(errors || {})?.length ? 1 : 0,
        formatter: (errors, batch) => {
            if (batch.nb_invalid > 0 || !batch.success)
                return (
                    <Button color="danger" size="sm" onClick={() => { showBatch(batch); }}>
                        <Icon path={ mdiEye } size={ 1 } color="white" />
                    </Button>
                );
            return '-';
        },
        headerFormatter: (col, index) => headerFormatter(col, index, 'errors'),
    }, {
        dataField: 'id',
        text: t('common.actions'),
        sort: false,
        formatter: actionFormatter,
        csvExport: false
    }];

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    return (
        <div className="BatchsList">
            <PageTitle
                title={ t('batchs.list_title') }
                icon={ <Icon path={ mdiSemanticWeb } color="white" /> }
            />
            {hasRunningError && (
                <ErrorMessage message={runningErrorMessage} />
            )}
            <Row>
                <Col sm={12}>
                    <StyledTable
                        bootstrap4
                        keyField="id"
                        data={ _.sortBy(batchs || [], 'created_at').reverse() }
                        columns={ columns }
                        defaultSorted={ defaultSorted }
                        sizePerPage= { 25 }
                    />
                </Col>
            </Row>
            {batchToShow &&
            <Modal backdrop={ true } size={'lg'} isOpen={mustShowModal} className="BatchModal" toggle={ toggleModal }>
                <ModalHeader toggle={ closeBatch }>
                    <div>
                        {t('batchs.batch')} { batchToShow.manager } - {batchToShow.filename}
                    </div>
                    <small>{ moment(batchToShow.created_at).format('LLL')}</small>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        {Object.keys(batchToShow.errors).length > 0 &&
                        <Col sm={12}>
                            <h4>{ t('batchs.errors') }</h4>
                            {Object.entries(batchToShow.errors).map(([key, errors], i) => (
                                <dl key={i}>
                                    <dt className="error_key" key={key + '-' + i}>{ key }</dt>
                                    <dd>
                                    { errors.map(error => (
                                        <div key={key + '-' + i + '-' + error} className="alert alert-danger">
                                            <Icon path={ mdiAlertCircleOutline } color="#721c24" size={ 1 } />
                                            { error }
                                        </div>
                                    ))}
                                    </dd>
                                </dl>
                            ))}
                        </Col>
                        }
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col sm={12} className="text-right">
                                <Button color="secondary" onClick={ closeBatch }>
                                    <Icon path={ mdiClose } size={1} color="white" />
                                    { t('common.close') }
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
            }
        </div>
    );
}

export default BatchsList;
